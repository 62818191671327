<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="3">
              <v-list dense subheader>
                <v-subheader style="font-size: 16px">Containers</v-subheader>
                <!-- {{containers}} -->
                <v-divider></v-divider>
                <v-list-item
                  v-for="container in containers"
                  :key="container.id"
                  @click="selectContainer(container)"
                  :style="{
                    'border-left':
                      selectedContainer && selectedContainer.id === container.id
                        ? '4px solid var(--v-primary-base)'
                        : '4px solid transparent',
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ container.containerNo }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ container.ctoNo }}
                    </v-list-item-subtitle>                    
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip v-if="!trackingLoading && tracking[container.id]" color="success" small> {{ tracking[container.id].length }} Tracking Sources</v-chip>
                    </v-list-item-action>   
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="9">
              <div >
                <ContainerTracking :container="selectedContainer" />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ContainerTracking from "../Tracking/ContainerTracking.vue";
export default {
  name: "BookingContainerTracking",
  props: {
    booking: {
      type: Object,
      required: true,
    },
    containers: {
      type: Array,
      required: true,
    },
  },
  components: {
    ContainerTracking,
  },
  data: () => ({
    selectedContainer: null,
    tracking: {},
    trackingLoading: false,
  }),
  watch: {
    containers: {
      handler() {
        if (this.containers.length > 0) {
          this.selectedContainer = this.containers[0];
          this.retrieveContainerTracking(this.containers.map((c) => c.id));
        }
      },
      immediate: true,
    },
  },
  methods: {
    async retrieveContainerTracking(containerIds) {
        this.trackingLoading = true;
      this.tracking = await this.$API.listContainerTracking(containerIds);
      this.trackingLoading = false
    },
    selectContainer(container) {
      this.selectedContainer = container;
    },
  },
};
</script>